<template>
  <b-row>
    <b-col cols="12">
      <user-profile-card :profile="userProfileData">
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="CreditCardIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.balance') }}</span>
          </div>
          <div class="text-success">
            {{ userProfileData.contact.balance > 0 ? `+${userProfileData.contact.balance}` : `-${userProfileData.contact.balance}` }} so'm
          </div>
        </b-list-group-item>
      </user-profile-card>
    </b-col>
    <b-col cols="12">

      <b-card>
        <b-table
          hover
          show-empty
          :fields="fields"
          :items="GET_USER_TRANSACTION_HISTORY.results"
          style-class="slots_text_align vgt-table bordered"
          :empty-text="$t('general.no_data_to_view')"
        >
          <template #head()="{label}">
            {{ $t(label) }}
          </template>
          <template #cell(description)="{value}">
            <div
              v-for="(item, index) in value"
              :key="index"
            >
              {{ item.title }}: <span class="text-danger">-{{ Math.round(item.price * 100)/100 }}</span>
            </div>
          </template>
        </b-table>
        <!-- pagination -->
        <!-- slot-scope="props" -->
        <template>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">{{ $t('general.show_from') }}</span>
              <b-form-select
                v-model="params.page_size"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @change="changePerPage"
              />
              <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
            </div>
            <div>
              <b-pagination
                v-model="params.page"
                :total-rows="GET_USER_TRANSACTION_HISTORY.count"
                :per-page="params.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BListGroupItem, BTable, BCard, BPagination, BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import userProfileCard from '../components/userProfileCard.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    userProfileCard,
    BCol,
    BListGroupItem,
    BTable,
    BRow,
    BCard,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          label: 'finance.date',
          key: 'paid_at',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: data => (new Date(data).toLocaleString().split(',')[0]),
        },
        {
          label: 'finance.old_balance',
          key: 'old_balance',
          tdClass: 'text-primary text-right',
        },
        {
          label: 'finance.income',
          key: 'income',
          tdClass: 'text-success text-right',
          formatter: data => (`+${data}`),
        },
        {
          label: 'finance.outcome',
          key: 'outcome',
          tdClass: 'text-danger text-right',
          formatter: data => (`-${data}`),
        },
        {
          label: 'finance.current_balance',
          key: 'new_balance',
          tdClass: 'text-primary text-right',
        },
        {
          label: 'finance.description',
          key: 'description',
        },
      ],
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
    }
  },
  computed: {
    ...mapGetters('finance', ['GET_USER_TRANSACTION_HISTORY']),
    userProfileData() {
      return useJwt.getUserData()
    },
  },
  mounted() {
    this.FETCH_USER_TRANSACTION_HISTORY(this.params)
  },
  methods: {
    ...mapActions('finance', ['FETCH_USER_TRANSACTION_HISTORY']),
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_USER_TRANSACTION_HISTORY(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_USER_TRANSACTION_HISTORY(this.params)
    },
    filter(val) {
      this.params.search = val.search
      this.FETCH_USER_TRANSACTION_HISTORY(val)
    },
  },
}
</script>

<style>

</style>
